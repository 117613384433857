import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';

type Props = {
    isDragging: boolean;
    isDisabled: boolean;
};

const DefaultDragDropZone = ({ isDragging, isDisabled }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return isDisabled ? null : (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            bgcolor={isDragging ? theme.palette.primary.main : theme.palette.uiColors.messageBox}
            border="2px dashed"
            borderColor={isDragging ? theme.palette.primary.dark : theme.palette.primary.main}
            borderRadius={1}
            sx={{ cursor: 'pointer' }}
        >
            <Typography color={isDragging ? 'white' : theme.palette.primary.main} fontWeight={600}>
                {isDragging ? t('documents.dragAndDrop.dropText') : t('documents.dragAndDrop.dragText')}
            </Typography>
        </Box>
    );
};

export { DefaultDragDropZone };
