import { updateMessageIdState, updateAudioState, updateLoadingState } from 'src/redux/reducers/MessageReducer';
import { AppThunk } from 'src/redux/store';
import { TextToSpeach } from 'src/service/speach/speach';

let currentAbortController: AbortController | null = null;

export const fetchTextToSpeech =
    (text: string, messageId: number): AppThunk =>
    async dispatch => {
        try {
            if (currentAbortController) {
                currentAbortController.abort();
            }

            const abortController = new AbortController();
            const signal = abortController.signal;
            currentAbortController = abortController;

            dispatch(updateLoadingState(true));
            dispatch(updateMessageIdState(messageId));

            const response = await TextToSpeach(text, signal);

            if (response) {
                dispatch(updateLoadingState(false));

                const byteCharacters = atob(response);
                const byteNumbers = new Array(byteCharacters.length);

                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const audioBlob = new Blob([byteArray], { type: 'audio/mp3' });
                const audioURL = URL.createObjectURL(audioBlob);
                dispatch(updateAudioState(audioURL));
            }

            return response;
        } catch (error: any) {
            if (error.name !== 'AbortError') {
                console.error('Error en la solicitud:', error);
            }
        } finally {
            dispatch(updateLoadingState(false));
            currentAbortController = null;
        }

        return null;
    };

export const cancelFetchTextToSpeech = () => {
    if (currentAbortController) {
        currentAbortController.abort();
        currentAbortController = null;
    }
};
