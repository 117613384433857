/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage } from '@features/chat/domain';

interface IMessageListState {
    messages: ChatMessage[];
    loading: boolean;
}

const initialState: IMessageListState = {
    messages: [],
    loading: false,
};

const MessageListStateSlice = createSlice({
    name: 'MessageListStateSlice',
    initialState,
    reducers: {
        initialValueState: () => initialState,
        resetValueState: state => {
            state.messages = [];
            state.loading = false;
        },

        updateMessageState: (state, action: PayloadAction<ChatMessage[]>) => {
            state.messages = action.payload;
        },
        updateLoadingState: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { initialValueState, resetValueState, updateMessageState, updateLoadingState } =
    MessageListStateSlice.actions;

export const MessageListStateReducer = MessageListStateSlice.reducer;
