import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './listenButton.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks/dispatch';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import { cancelFetchTextToSpeech, fetchTextToSpeech } from 'src/redux/action/message/message';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { updateLisentState, updateMessageIdState, updateAudioState } from 'src/redux/reducers/MessageReducer';
import ClearIcon from '@mui/icons-material/Clear';
type Props = {
    text: string;
    disabled?: boolean;
    messageId: number;
};

export const LisentButton = ({ text, disabled = false, messageId }: Props) => {
    const { loading, isLisent, messageId: Id, audio } = useAppSelector(state => state.messageState);
    const { t } = useTranslation();
    const lisentTitle = t('general.listenTo');
    const cancelTitle = t('general.cancel');
    const dispatch = useAppDispatch();

    const [audioInstance, setAudioInstance] = useState<HTMLAudioElement | null>(null);

    const lisentText = () => {
        dispatch(fetchTextToSpeech(text, messageId));
    };
    const stopLisent = () => {
        if (audioInstance && !audioInstance?.paused) {
            audioInstance.pause();
            dispatch(updateLisentState(false));
            setAudioInstance(null);
        }
    };

    const resetInstance = () => {
        dispatch(updateLisentState(false));
        dispatch(updateMessageIdState(undefined));
        dispatch(updateAudioState(undefined));
        setAudioInstance(null);
    };
    useEffect(() => {
        const playAudio = async () => {
            if (audio && messageId === Id) {
                const speech = new Audio(audio);
                setAudioInstance(speech);
                await speech.play();
                dispatch(updateLisentState(true));

                speech.onplay = () => {
                    dispatch(updateLisentState(true));
                };
                speech.onended = () => {
                    resetInstance();
                };
                speech.onerror = () => {
                    resetInstance();
                };
            }
        };

        playAudio();

        return () => {
            if (audioInstance) {
                audioInstance.pause();
                audioInstance.currentTime = 0;
                resetInstance();
            }
        };
    }, [audio]);

    return loading && messageId === Id ? (
        <>
            <CircularProgress size={30} />
            <IconButton
                type="button"
                onClick={() => {
                    cancelFetchTextToSpeech();
                }}
                id="cancel-button"
                title={cancelTitle}
                data-testid="cancel-button"
            >
                <ClearIcon />
            </IconButton>
        </>
    ) : (
        <IconButton
            type="button"
            className={isLisent && messageId === Id ? 'pulsing' : ''}
            onClick={!isLisent ? lisentText : stopLisent}
            disabled={disabled || (isLisent && messageId !== Id) || loading}
            id="lisent-button"
            title={lisentTitle}
            data-testid="lisent-button"
        >
            {isLisent && messageId === Id ? <VolumeUpIcon /> : <VolumeMuteIcon />}
        </IconButton>
    );
};
