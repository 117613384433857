/* eslint-disable no-debugger */
/* eslint-disable no-empty */
import i18next from 'i18next'; // Importa la instancia de i18next

import { updateMessageState, updateLoadingState, updateErrorMessageState } from 'src/redux/reducers/MessageBoxReducer';
import { AppThunk, store } from 'src/redux/store';
import { speachToText } from 'src/service/speach/speach';

export const fetchSpeachToText =
    (file: File): AppThunk =>
    async dispatch => {
        try {
            dispatch(updateLoadingState(true));
            dispatch(updateErrorMessageState(''));
            const { message } = store.getState().messageBoxState;
            const response = await speachToText(file);
            if (response) {
                dispatch(updateMessageState(message + ' ' + response));
            } else {
                dispatch(updateErrorMessageState(i18next.t('errors.transcriptionError')));
            }
            return response;
        } catch (error) {
        } finally {
            dispatch(updateLoadingState(false));
        }

        return null;
    };
