import { createTheme, PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';
declare module '@mui/material/styles' {
    interface Palette {
        uiColors: {
            messageBox: string;
            userMessage: string;
            gptMessage: string;
            documentMessageText: string;
        };
    }

    interface PaletteOptions {
        uiColors?: {
            messageBox?: string;
            userMessage: string;
            gptMessage: string;
            documentMessageText: string;
        };
    }
}
const customTheme = (mode: PaletteMode = 'dark') =>
    createTheme({
        palette: {
            mode,
            primary: {
                main: '#4eba71', // Color principal
                light: '#82eda0',
                dark: '#098945',
            },
            secondary: {
                main: '#0d1c36', // Color secundario
                dark: '#000011',
                light: '#374260',
            },
            error: {
                main: mode === 'dark' ? '#ff5555' : '#db202f', // Color de error
            },
            text: {
                primary: mode === 'dark' ? '#FFFFFF' : '#101010',
                secondary: '#222',
                disabled: '#777',
            },
            background: {
                default: mode === 'dark' ? '#303030' : '#FFFFFF', // Fondo de la página
                paper: mode === 'dark' ? '#424242' : '#FFFFFF', // Fondo de papeles (cards, diálogos, etc.)
            },
            uiColors: {
                messageBox: mode === 'dark' ? '#424242' : grey[300],
                gptMessage: mode === 'dark' ? '#303030' : grey[200],
                userMessage: mode === 'dark' ? '#424242' : grey[50],
                documentMessageText: mode === 'dark' ? '#bbbbbb' : grey[600],
            },
        },
        typography: {
            fontFamily: 'Open Sans',
            fontSize: 14,
            subtitle1: {
                fontFamily: 'Open Sans',
                fontSize: '1.2rem',
                fontWeight: 600,
            },
            subtitle2: {
                fontFamily: 'Open Sans',
                fontSize: '1rem',
                fontWeight: 400,
            },
            body1: {
                fontFamily: 'Open Sans',
            },
            caption: {
                fontFamily: 'Open Sans',
                color: mode === 'dark' ? '#FFFFFF' : '#101010',
            },
            overline: {
                fontFamily: 'Open Sans',
            },
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#0d1c36', // Color del AppBar
                        backgroundImage: 'none',
                    },
                },
            },
        },
    });

export { customTheme };
