import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMessageState {
    loading: boolean;
    isLisent: boolean;
    audio: string | undefined;
    messageId: number | undefined;
}

const initialState: IMessageState = {
    loading: false,
    isLisent: false,
    audio: undefined,
    messageId: undefined,
};

const MessageStateSlice = createSlice({
    name: 'MessageReducer',
    initialState,
    reducers: {
        initialValueState: () => initialState,
        resetValueState: state => {
            state.loading = false;
        },
        updateLoadingState: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateLisentState: (state, action: PayloadAction<boolean>) => {
            state.isLisent = action.payload;
        },
        updateAudioState: (state, action: PayloadAction<string | undefined>) => {
            state.audio = action.payload;
        },
        updateMessageIdState: (state, action: PayloadAction<number | undefined>) => {
            state.messageId = action.payload;
        },
    },
});

export const {
    initialValueState,
    resetValueState,
    updateLoadingState,
    updateLisentState,
    updateAudioState,
    updateMessageIdState,
} = MessageStateSlice.actions;

export const MessageStateReducer = MessageStateSlice.reducer;
