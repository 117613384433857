import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { MessageBoxStateReducer } from './reducers/MessageBoxReducer';
import { MessageListStateReducer } from './reducers/MessageListReducer';
import { MessageStateReducer } from './reducers/MessageReducer';
import { AppStateReducer } from './reducers/AppReducer';

export const store = configureStore({
    reducer: {
        messageBoxState: MessageBoxStateReducer,
        messageListState: MessageListStateReducer,
        messageState: MessageStateReducer,
        AppState: AppStateReducer,
    },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, any, Action<string>>;
