/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMessageBoxState {
    message: string;
    error: string;
    loading: boolean;
}

const initialState: IMessageBoxState = {
    message: '',
    loading: false,
    error: '',
};

const MessageBoxStateSlice = createSlice({
    name: 'MessageBoxReducer',
    initialState,
    reducers: {
        initialValueState: () => initialState,
        resetValueState: state => {
            state.message = '';
            state.error = '';
            state.loading = false;
        },

        updateMessageState: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
        updateLoadingState: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateErrorMessageState: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
    },
});

export const { initialValueState, resetValueState, updateMessageState, updateErrorMessageState, updateLoadingState } =
    MessageBoxStateSlice.actions;

export const MessageBoxStateReducer = MessageBoxStateSlice.reducer;
