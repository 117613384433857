import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAppState {
    darkMode: boolean;
}

const initialState: IAppState = {
    darkMode: false,
};

const AppStateSlice = createSlice({
    name: 'AppReducer',
    initialState,
    reducers: {
        initialValueState: () => initialState,
        resetValueState: state => {
            state.darkMode = false;
        },
        updateDarkModeState: (state, action: PayloadAction<boolean>) => {
            state.darkMode = action.payload;
        },
    },
});

export const { initialValueState, resetValueState, updateDarkModeState } = AppStateSlice.actions;

export const AppStateReducer = AppStateSlice.reducer;
