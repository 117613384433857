/* eslint-disable no-debugger */
import { agent } from '@shared/api';
interface ISpeacResponse {
    value: string;
}
export const speachToText = async (file: File, signal?: AbortSignal) => {
    try {
        const data = new FormData();
        data.append('formFile', file);
        const response = await agent.postFormData<FormData, ISpeacResponse>('/api/speech/speechtotext', data, signal);
        return response.value;
    } catch (error) {
        return '';
    }
};

export const TextToSpeach = async (text: string, signal?: AbortSignal) => {
    try {
        const response = await agent.post<string, ISpeacResponse>(`/api/speech/texttospeech`, text, signal);
        return response.value;
    } catch (error) {
        return '';
    }
};
