import { Box, useTheme } from '@mui/material';
import { CopyButton, WritingPoints } from '@shared/ui';
import { ChatMessage } from '../domain';
import { MessageAvatar } from '../messageAvatar';
import { MessageElement } from './MessageElement';
import { LisentButton } from '../../../shared/ui/lisentButton/listenButton';
type Props = {
    chatMessage: ChatMessage;
    isSendingQuestion: boolean;
    index?: number;
};

const MessageContent = ({ chatMessage, isSendingQuestion, index }: Props) => {
    const theme = useTheme();
    return (
        <Box
            bgcolor={
                chatMessage.role === 'user' ? theme.palette.uiColors.userMessage : theme.palette.uiColors.gptMessage
            }
            color={theme.palette.text.primary}
            width={'100%'}
            padding={2}
            data-testid="message-content"
        >
            <Box maxWidth={1024} margin={'auto'} display={'flex'} justifyContent={'start'} alignItems={'start'} gap={2}>
                <MessageAvatar role={chatMessage.role} />
                {chatMessage.role !== 'loading' ? (
                    <MessageElement role={chatMessage.role} text={chatMessage.text} />
                ) : (
                    <WritingPoints />
                )}

                {chatMessage.role !== 'document' && chatMessage.role !== 'documentError' ? (
                    <CopyButton textToCopy={chatMessage.text} disabled={isSendingQuestion} />
                ) : null}
                {chatMessage.role !== 'document' && chatMessage.role !== 'documentError' ? (
                    <LisentButton text={chatMessage.text} disabled={isSendingQuestion} messageId={index ?? 0} />
                ) : null}
            </Box>
        </Box>
    );
};

export { MessageContent };
