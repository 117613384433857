/* eslint-disable no-debugger */
import { BrowserRouter as Router } from 'react-router-dom';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { customTheme } from '@assets/styles';
import { configureAppInsights } from '@features/logging';
import { ApplicationRoutes } from '@features/router';
import { DocumentsSettings, LinksSettings, ModelServiceSettings } from '@features/settings';
import { ErrorPage } from '@pages/ErrorPage';
import './assets/styles/global.scss';
import './assets/styles/scrollbar.scss';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks/dispatch';
import { RootState } from './redux/store';
import { updateDarkModeState } from './redux/reducers/AppReducer';

type Props = {
    appInsightKey: string;
    links: LinksSettings;
    modelService: ModelServiceSettings;
    documentSettings: DocumentsSettings;
};

const App = ({ appInsightKey, links, modelService, documentSettings }: Props) => {
    const { reactPlugin } = configureAppInsights(appInsightKey);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const { darkMode } = useAppSelector((state: RootState) => state.AppState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const storedDarkMode = localStorage.getItem('darkMode');
        const shouldUseDarkMode = storedDarkMode === 'true' || (storedDarkMode === null && prefersDarkMode);

        if (darkMode !== shouldUseDarkMode) {
            dispatch(updateDarkModeState(shouldUseDarkMode));
        }
        localStorage.setItem('darkMode', String(shouldUseDarkMode));
    }, [darkMode, prefersDarkMode, dispatch]);
    return (
        <>
            <CssBaseline />
            <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <ThemeProvider theme={customTheme(darkMode ? 'dark' : 'light')}>
                        <Router>
                            <ApplicationRoutes
                                links={links}
                                modelService={modelService}
                                documentSettings={documentSettings}
                            />
                        </Router>
                    </ThemeProvider>
                </AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
        </>
    );
};

export { App };
