import React from 'react';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import './microphone.scss';
import { useAudioRecorder } from 'src/hooks/speach-to-recognition';

export const SpeechRecognitionComponent: React.FC = () => {
    const { isRecording, startRecording, stopRecording } = useAudioRecorder();

    return (
        <div style={{ textAlign: 'center', marginTop: '5px', marginLeft: '5px', marginRight: '10px' }}>
            <IconButton
                className={isRecording ? 'pulsing' : ''}
                size="medium"
                onClick={!isRecording ? startRecording : stopRecording}
            >
                <MicIcon></MicIcon>
            </IconButton>
        </div>
    );
};
