import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchSpeachToText } from 'src/redux/action/messageBox/messageBox';
import { useAppDispatch } from 'src/redux/hooks/dispatch';
import { updateErrorMessageState } from 'src/redux/reducers/MessageBoxReducer';
import { getWaveBlob } from 'webm-to-wav-converter';

type UseAudioRecorderReturn = {
    isRecording: boolean;
    startRecording: () => Promise<void>;
    stopRecording: () => void;
    resetRecording: () => void;
};

export const useAudioRecorder = (): UseAudioRecorderReturn => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const mediaStreamRef = useRef<MediaStream | null>(null); // Guardar el flujo de medios
    const audioChunks = useRef<Blob[]>([]);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const audioContextRef = useRef<AudioContext | null>(null);
    const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
    const lastSpeechTimeRef = useRef<number>(Date.now());
    const analysisIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const maxVolume = 5;

    const startRecording = async (): Promise<void> => {
        try {
            resetRecording();
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStreamRef.current = stream;
            mediaRecorder.current = new MediaRecorder(stream);

            audioContextRef.current = new AudioContext();
            sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 256;
            sourceRef.current.connect(analyserRef.current);

            mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorder.current.onstop = async () => {
                const webmBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                try {
                    const wavBlob = await getWaveBlob(webmBlob, false);
                    const file = new File([wavBlob], 'audio.wav', { type: 'audio/wav' });
                    dispatch(updateErrorMessageState(''));
                    dispatch(fetchSpeachToText(file));
                } catch (error) {
                    console.error('Error converting WebM to WAV:', error);
                }

                // Limpiar datos
                audioChunks.current = [];
                stopAnalysis();
                stopMediaStream(); // Detener el flujo de medios
            };
            dispatch(updateErrorMessageState(''));
            mediaRecorder.current.start(100);
            setIsRecording(true);
            startAnalysis();
        } catch (error) {
            alert(t('errors.microphoneAccessed'));
        }
    };

    const stopRecording = (): void => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            setIsRecording(false);
        }
    };

    const stopMediaStream = (): void => {
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
            mediaStreamRef.current = null;
        }
    };

    const resetRecording = (): void => {
        setIsRecording(false);
        audioChunks.current = [];
        if (mediaRecorder.current) {
            mediaRecorder.current = null;
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        if (analyserRef.current) {
            analyserRef.current = null;
        }
        if (sourceRef.current) {
            sourceRef.current = null;
        }
        lastSpeechTimeRef.current = 0;
        stopAnalysis();
        stopMediaStream(); // Detener el flujo de medios al reiniciar
    };

    const startAnalysis = (): void => {
        analysisIntervalRef.current = setInterval(analyzeAudio, 100);
    };

    const stopAnalysis = (): void => {
        if (analysisIntervalRef.current) {
            clearInterval(analysisIntervalRef.current);
            analysisIntervalRef.current = null;
        }
    };

    const analyzeAudio = (): void => {
        if (analyserRef.current) {
            const bufferLength = analyserRef.current.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyserRef.current.getByteFrequencyData(dataArray);

            const averageVolume = dataArray.reduce((acc, value) => acc + value, 0) / bufferLength;
            const currentTime = Date.now();

            if (!lastSpeechTimeRef.current) {
                if (averageVolume > maxVolume) {
                    lastSpeechTimeRef.current = currentTime;
                }
                return;
            }

            if (averageVolume > maxVolume) {
                lastSpeechTimeRef.current = currentTime;
            } else {
                const silenceDuration = (currentTime - lastSpeechTimeRef.current) / 1000;

                if (silenceDuration > 2) {
                    stopRecording();
                }
            }
        }
    };

    return {
        isRecording,
        stopRecording,
        startRecording,
        resetRecording,
    };
};
