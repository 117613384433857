import { DocumentsSettings } from '@features/settings';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMediaQuery, useTheme } from '@mui/system';
import { UploadButton } from '@shared/ui/uploadButton';
import { ChangeEvent, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentBannerConfiguration, DocumentUpload } from '../documentUpload';
import { useDocumentUpload } from '../documentUpload/useDocumentUpload';
import { ChatMessage } from '../domain';
import { TokenCounter } from '../tokenCounter';
import { componentsMediaQueries } from '@assets/mediaQuery/media-query';
import { SpeechRecognitionComponent } from './microphone/microphone';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks/dispatch';
import { updateErrorMessageState, updateMessageState } from 'src/redux/reducers/MessageBoxReducer';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
    sendMessage: (value: string) => void;
    isSendingQuestion: boolean;
    maxTokens: number;
    tokenCounterInfoUrl: string;
    setDivMessageBoxHeight: (value: number) => void;
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
    documentSettings: DocumentsSettings;
    chatId: string;
    setChatId: (value: string) => void;
    setMessages: (value: SetStateAction<ChatMessage[]>) => void;
    setDisableDocumentUpload: (value: boolean) => void;
    disableDocumentUpload: boolean;
    setManageBanner: (value: SetStateAction<DocumentBannerConfiguration>) => void;
};

const MessageBox = ({
    sendMessage,
    isSendingQuestion,
    maxTokens,
    tokenCounterInfoUrl,
    isFocused,
    setIsFocused,
    documentSettings,
    chatId,
    setChatId,
    setMessages,
    setDisableDocumentUpload,
    disableDocumentUpload,
    setManageBanner,
}: Props) => {
    const { message, error, loading } = useAppSelector(state => state.messageBoxState);
    const { t } = useTranslation();
    const theme = useTheme();
    const [disableSendingQuestion, setDisableSendingQuestion] = useState(false);
    const [isDraggedInMessageBoxWrapper, setIsDraggedInMessageBoxWrapper] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const divWrapperRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery(componentsMediaQueries.TABLET);
    const dispatch = useAppDispatch();
    const disableSendButton = isSendingQuestion || disableSendingQuestion || message === '';

    const clickHandler = () => {
        const messageTrimmed = message.trim();
        if (messageTrimmed !== '') {
            sendMessage(messageTrimmed);
            dispatch(updateMessageState(''));
            setIsFocused(true);
        }
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isReady = !isSendingQuestion && !disableSendingQuestion;
        const sendKeyPressed = e.key === 'Enter' && !e.shiftKey;
        if (sendKeyPressed && isReady) {
            clickHandler();
            e.preventDefault();
        } else {
            error !== '' && setErrorMessage('');
        }
    };

    const onChangeChatTextBox = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value || '';
        dispatch(updateMessageState(value));
    };
    const setErrorMessage = (value: string) => {
        dispatch(updateErrorMessageState(value));
    };
    const {
        isFileDraggedInScreen,
        isDraggingInsideZone,
        uploadingDocStatus,
        setIsDraggingInsideZone,
        setIsFileDraggedInScreen,
        handleChangeUpload,
        handleFileErrors,
    } = useDocumentUpload({
        setMessages,
        setChatId,
        isDraggedInMessageBoxWrapper,
        chatId,
        setDisableDocumentUpload,
        setErrorMessage,
    });
    useLayoutEffect(() => {
        if (isFocused && !isSendingQuestion) {
            inputRef?.current?.focus();
            setIsFocused(false);
        }
    }, [isFocused, isSendingQuestion]);

    useEffect(() => {
        const showBanner = uploadingDocStatus === 'started' || uploadingDocStatus === 'finished';
        setManageBanner({
            show: showBanner,
            status: uploadingDocStatus,
        });
        setErrorMessage('');
    }, [uploadingDocStatus]);

    return (
        <Box
            width="100%"
            maxWidth={1024}
            minHeight={72}
            sx={{
                margin: 'auto',
                marginLeft: isMobile ? 1 : 'auto',
                marginRight: isMobile ? 1 : 'auto',
                marginTop: 1,
                position: 'relative',
                bottom: 0,
                left: 0,
                right: 0,
                userSelect: 'none',
            }}
            ref={divWrapperRef}
            onDragEnter={() => setIsDraggedInMessageBoxWrapper(true)}
            onDragLeave={() => setIsDraggedInMessageBoxWrapper(false)}
        >
            <Box borderRadius={1} bgcolor={theme.palette.uiColors.messageBox} zIndex={1} position="relative">
                <Box display="flex" alignItems="end" width="100%" position="relative" marginBottom={0.5}>
                    <SpeechRecognitionComponent></SpeechRecognitionComponent>

                    <TextField
                        variant="standard"
                        multiline
                        placeholder={t('chat.textfieldPlaceholder')}
                        data-testid="text-field-message-box"
                        sx={{
                            bgcolor: theme.palette.uiColors.messageBox,
                            minHeight: 52,
                            maxHeight: 250,
                            overflow: 'auto',
                            padding: 2,
                            paddingRight: isMobile ? 1 : 16,
                            paddingBottom: 0,
                            marginBottom: 1,
                            borderRadius: 1,
                            flex: '1 1 auto',
                            marginRight: '5px',
                        }}
                        onChange={onChangeChatTextBox}
                        onKeyDown={keyDownHandler}
                        value={message}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        disabled={isSendingQuestion}
                        autoFocus
                        inputRef={inputRef}
                    />
                    {!loading && message && (
                        <IconButton
                            sx={{ marginRight: 1 }}
                            type="button"
                            onClick={() => {
                                dispatch(updateMessageState(''));
                            }}
                            id="cancel-button"
                            title={t('general.cleanText')}
                            data-testid="cancel-button"
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                    {!loading && (
                        <UploadButton
                            handleChange={handleChangeUpload}
                            documentSettings={documentSettings}
                            isDisabled={disableDocumentUpload}
                            handleErrors={handleFileErrors}
                        />
                    )}
                    {loading && <CircularProgress sx={{ marginRight: 1, marginBottom: 1 }} />}
                    {!loading && (
                        <Button
                            type="submit"
                            onClick={clickHandler}
                            sx={{
                                minWidth: 40,
                                maxHeight: 40,
                                minHeight: 40,
                                marginX: 1,
                                position: 'relative',
                                right: 0,
                                bgcolor: theme.palette.primary.main,
                                '&:hover': { bgcolor: theme.palette.primary.dark },
                                '&:disabled': { bgcolor: grey[400] },
                            }}
                            disabled={disableSendButton}
                        >
                            <SendIcon sx={{ color: 'white' }} />
                        </Button>
                    )}
                </Box>

                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flex={1}>
                    {error && (
                        <Box
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            width="100%"
                            color={theme.palette.error.main}
                            paddingLeft={2}
                            paddingBottom={1}
                            gap={0.5}
                        >
                            <ErrorOutlineOutlinedIcon sx={{ width: 16 }} />
                            <Typography color={theme.palette.error.main} variant="caption">
                                {error}
                            </Typography>
                        </Box>
                    )}
                    <TokenCounter
                        maxTokens={maxTokens}
                        text={message}
                        tokenCounterInfoUrl={tokenCounterInfoUrl}
                        setDisableSendingQuestion={setDisableSendingQuestion}
                    />
                </Box>
            </Box>

            <DocumentUpload
                formatsAllowed={documentSettings.formatsAllowed}
                docSize={documentSettings.size}
                isDisabled={disableDocumentUpload}
                handleChangeUpload={handleChangeUpload}
                handleErrors={handleFileErrors}
                isDraggingInsideZone={isDraggingInsideZone}
                isFileDraggedInScreen={isFileDraggedInScreen}
                setIsDraggingInsideZone={setIsDraggingInsideZone}
                setIsFileDraggedInScreen={setIsFileDraggedInScreen}
            />
        </Box>
    );
};

export { MessageBox };
